import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { DecimalToTimePipe } from '../pipes/decimal-to-time.pipe';
import { DecimalPipe } from '../pipes/decimal.pipe';

export const DECIMAL_TIME_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DecimalTimeInputComponent),
  multi: true,
};

@Component({
  selector: 'decimal-time-input',
  template: `
    <input
      class="form-modal__input-short decimal-time-input h-[34px]"
      type="text"
      [formControl]="control"
      (change)="onChangeInput()"
      (keydown.ArrowUp)="incrementInteger($event)"
      (keydown.ArrowDown)="decrementInteger($event)"
      (blur)="onBlur()"
    />
  `,
  providers: [DecimalToTimePipe, DECIMAL_TIME_INPUT_CONTROL_VALUE_ACCESSOR],
  imports: [DecimalToTimePipe, ReactiveFormsModule],
  standalone: true,
})
export class DecimalTimeInputComponent implements ControlValueAccessor {
  public control = new UntypedFormControl(undefined);

  @Input()
  decimals: number = 5;
  @Input()
  minuteStep: number = 15;

  value: string;

  // internal functions to call when ControlValueAccessor
  // gets called
  onTouched: Function;
  onModelChange: Function;

  constructor(private decimalToTimePipe: DecimalToTimePipe, private decimalPipe: DecimalPipe) {}

  incrementInteger(event: KeyboardEvent) {
    event.preventDefault();
    this.add(this.minuteStep);
  }

  decrementInteger(event: KeyboardEvent) {
    event.preventDefault();
    this.add(-1 * this.minuteStep);
  }

  add(minutes) {
    const value = parseFloat(this.value) + minutes / 60;

    this.control.setValue(this.transform(value.toFixed(this.decimals)));

    this.onChange(value.toFixed(this.decimals));
  }

  onBlur() {
    this.control.setValue(this.transform(this.value));
    this.onTouched();
  }

  // our custom onChange method
  onChangeInput() {
    const value = this.parse(this.control.value ? this.control.value : '0');
    this.onChange(value);
  }

  onChange(value: string) {
    this.value = value;
    this.onModelChange(value);
  }

  private parse(displayValue: string) {
    if (displayValue.includes(':')) {
      return this.decimalToTimePipe.parse(displayValue, this.decimals);
    }

    return this.decimalPipe.parse(displayValue, this.decimals);
  }

  private transform(value: string) {
    return this.decimalToTimePipe.transform(value);
  }

  // called by the reactive form control
  // writes the value to the local component
  // that binds to the "value"
  writeValue(value: string) {
    if (!value) {
      value = '0';
    }
    this.value = value;
    this.control.setValue(this.transform(this.value));
  }

  // called by the reactive form control
  registerOnChange(fn: Function) {
    // assigns to our internal model change method
    this.onModelChange = fn;
  }

  // called by the reactive form control
  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  // called by the reactive form control
  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
}
